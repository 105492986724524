import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "8 Productivity Tips for Github",
  "date": "2019-01-09T18:00:00.000Z",
  "layout": "post",
  "draft": false,
  "path": "/posts/github-tips",
  "category": "Software",
  "tags": ["git", "github", "productivity", "tips"],
  "description": "A collection of tips and tricks that'll improve your GitHub productivity."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`With the recent announcement of `}<a parentName="p" {...{
        "href": "https://blog.github.com/2019-01-07-new-year-new-github/"
      }}>{`unlimited private repositories`}</a>{`, let's take a few minutes before we push up the code we don’t want anyone else to see, and make sure we’re making the most of what GitHub has to offer.`}</p>
    <p>{`GitHub is built with some extremely helpful shortcuts and productivity-boosting features. From personal experience, however, it’s clear that these often fall under the radar amongst developers. If I’ve ever witnessed a specific GitHub feature surprise or assist someone, then that feature is on this page. That said, what follows is by no means an exhaustive list.`}</p>
    <h2>{`Quick fuzzy file search in repositories`}</h2>
    <p>{`This is, without doubt, the fastest way to browse a repository when you know what you’re looking for. Open up any repository and press `}<inlineCode parentName="p">{`t`}</inlineCode>{`. You can now search for the name of any file in the repository, and use the arrow keys on your keyboard to move through the results. Press Enter to open the file.`}</p>
    <p><img parentName="p" {...{
        "src": "/8314ed3032f27ad5d6d47ba3f17f954a/gh-fuzzy.gif",
        "alt": "github-fuzzy-search"
      }}></img></p>
    <h2>{`Code change suggestions in pull requests`}</h2>
    <p>{`When commenting on a piece of code in a pull request, you can suggest alternative code using the “Suggested Changes” feature. The author of the pull request will be able to apply your suggestion instantly without leaving GitHub.`}</p>
    <p>{`To make the suggestion, surround a code snippet with a multiline Markdown snippet, with the tag `}<inlineCode parentName="p">{`suggestion`}</inlineCode>{`:`}</p>
    <p><img parentName="p" {...{
        "src": "/c25837137c065943784cd16030d2d8d3/gh-make-suggestion.gif",
        "alt": "gh-make-suggestion"
      }}></img></p>
    <p>{`Now that you’ve made the suggestion, the author of the pull request can immediately apply it to their branch, without the hassle of manually changing the file!`}</p>
    <p><img parentName="p" {...{
        "src": "/ee947a46af5e0419931a336dc01ce6b7/gh-apply-suggestion.gif",
        "alt": "gh-apply-suggestion"
      }}></img></p>
    <h2>{`Navigate the code tree like in an IDE`}</h2>
    <p>{`This one requires an unofficial Chrome extension, but it’s a slightly more familiar way to navigate your code compared to the default interface. The `}<a parentName="p" {...{
        "href": "https://chrome.google.com/webstore/detail/octotree/bkhaagjahfmjljalopjnoealnfndnagc"
      }}>{`Octotree extension`}</a>{` lets you browse GitHub repositories with a sidebar tree view similar to what you get in applications like VS Code.`}</p>
    <p><img parentName="p" {...{
        "src": "/8a29cec0f827b1eb44f4fb0c9191919f/gh-octotree.gif",
        "alt": "octotree"
      }}></img></p>
    <h2>{`Jump to a function when reviewing code`}</h2>
    <p>{`Unless you’re reviewing a single function, a code review often involves a lot of jumping between function calls and their definitions (and therefore a lot of scrolling up and down). GitHub lets you jump to a symbol by pressing `}<inlineCode parentName="p">{`t`}</inlineCode>{` when you’re looking at files in a pull request.`}</p>
    <p><img parentName="p" {...{
        "src": "/5e96b4f348b46356ecb9d256ac4a287e/gh-jump-to-symbol.gif",
        "alt": "gh-jump-to-symbol"
      }}></img></p>
    <h2>{`Creating a permalink to a file`}</h2>
    <p>{`When viewing a file or directory, press `}<inlineCode parentName="p">{`y`}</inlineCode>{`, and the URL will be converted to a permalink, which you can share knowing that the contents of the file will never change.`}</p>
    <p>{`If you send a link to a file or directory on GitHub without making it into a permalink, you’ll need to accept the possibility that the file could disappear tomorrow, breaking the link!`}</p>
    <h2>{`Viewing the blame and change recency heatmap`}</h2>
    <p>{`When viewing a file, you can press `}<inlineCode parentName="p">{`b`}</inlineCode>{` to view the Git blame and a heatmap showing how recently each line was changed. It’ll tell you who most recently changed each line of code, and give you a clickable link taking you to the full commit the change was part of.`}</p>
    <p>{`On the right-hand side of the gutter (which contains the commit message and author), you’ll notice an orange vertical bar. The more vivid this bar is, the more recent the change, meaning you can easily scan the file to find the freshest code!`}</p>
    <p><img parentName="p" {...{
        "src": "/f4013c9395d6183794570b1b7ac45e9f/gh-blame.gif",
        "alt": "gh-blame"
      }}></img></p>
    <h2>{`Powerful code search`}</h2>
    <p>{`GitHub indexes most code and offers powerful search functionality over it. If you need to find something in a repository, but don’t plan on making any changes to it. There’s usually no need to check the repository out. Press `}<inlineCode parentName="p">{`/`}</inlineCode>{`  to search all the code in the repository.`}</p>
    <p><img parentName="p" {...{
        "src": "/d1c1bfe3834361c4b6a4e061b214473f/gh-code-search.gif",
        "alt": "gh-code-search"
      }}></img></p>
    <p>{`If your search contains multiple words and you want to search for occurrences of your specific search query, put the quotations around the query. You can filter your searches by other things too, such as file size, extension, the path the file is on, and much more.`}</p>
    <h2>{`Saved replies`}</h2>
    <p>{`If you ever find yourself repeating the same comments, you’ll save some time by creating a `}<a parentName="p" {...{
        "href": "https://github.com/settings/replies"
      }}>{`saved reply`}</a>{`. The next time you find you’re going to type that comment again, you can instead just select it from a drop-down menu:`}</p>
    <p><img parentName="p" {...{
        "src": "/629969b7b687289f1bb3e47cea960b09/gh-saved-replies.gif",
        "alt": "gh-saved-replies"
      }}></img></p>
    <p>{`To perform the above action without using my mouse, I can do `}<inlineCode parentName="p">{`ctrl + .`}</inlineCode>{` followed by `}<inlineCode parentName="p">{`ctrl + 1`}</inlineCode>{`.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`Thanks for reading. I hope you found at least one thing on this page that will make you a more productive GitHub user. If you enjoyed this post or have any feedback in general, let me know! If you’re interested in more content like this, follow my `}<a parentName="p" {...{
        "href": "https://twitter.com/_darrenburns"
      }}>{`Twitter`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      